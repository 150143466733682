"use client";
import Image from "next/image";
import Link from "next/link";
import { useState, useEffect } from "react";
import styles from "@/app/styles/home.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import https from "https";
import Loading from "./loading";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useDeviceType from "@/app/utils/useDeviceType";
import TruncatedText from "@/app/components/read-more";
import InstagramFeed from '@/app/components/instagram';
import { Carousel } from 'react-bootstrap';
import { formatEventDate } from '@/app/utils/formatDate';
import {fetchInstagramUserId } from '@/app/utils/instagram';

gsap.registerPlugin(ScrollTrigger);

const agent = new https.Agent({
  rejectUnauthorized: false,
});

async function getHomePage() {
  // Define separate queries
  const homePageQuery = `
  {
  homePage: pageBy(id: "cG9zdDoxMDc=") {
    homePage {
      awardsSectionCreativeHeading
      awardsSectionDescription
      awardsSectionTitle
      welcomeSectionTitle
      welcomeSectionDescription
      fieldGroupName
      bannerType
      bannerVideo {
        node {
          mediaItemUrl
        }
      }
      drinkMenuCreativeHeading
      drinkMenuDescription
      drinkMenuFeatureImage {
        node {
          mediaItemUrl
        }
      }
      drinkMenuFeatureVideo {
        node {
          mediaItemUrl
        }
      }
      drinkMenuSampleList {
        drink
      }
      drinkMenuTitle
      foodMenuCreativeHeading
      foodMenuDescription
      foodMenuFeatureImage {
        node {
          mediaItemUrl
        }
      }
      foodMenuTitle
      locationList {
        description
        location
        icon {
          node {
            mediaItemUrl
          }
        }
      }
      testimonialsCreativeHeading
      testimonialsTitle
      entertainmentCreativeHeading
      entertainmentDescription
      entertainmentTitle
      eventsAndOffersCreativeHeading
      eventsAndOffersTitle
      disableDrinkMenu
      disableEntertainment
      disableEventsAndOffers
      disableFoodMenu
      disableTestimonials
      bannerMobileVideo {
        node {
          mediaItemUrl
        }
      }
      featureMobileVideo {
        node {
          mediaItemUrl
        }
      }
      disableInstagramFeed
      disableSin
      instagramCreativeHeading
      instagramProfileName
      instagramTitle
      instagramUrl
      sinDescription
      sinFeaturesd {
        link
        title
        image {
          node {
            mediaItemUrl
          }
        }
      }
      sinWebsiteUrl
      sinLogo {
        node {
          mediaItemUrl
        }
      }
      bannerSliders {
        bannerImage1920X1080 {
          node {
            mediaItemUrl
          }
        }
      }
      drinkMenu {
        node {
          mediaItemUrl
        }
      }
      foodMenu {
        node {
          mediaItemUrl
        }
      }
      awardsAndQuotesList {
        type
        quoteSubtitle
        quote
        award {
          nodes {
            ... on Qlaward {
              id
              awardsPost {
                awardsImage {
                  node {
                    mediaItemUrl
                  }
                }
              }
            }
          }
        }
      }
      upcomingOffers {
        eventAndOffer {
          nodes {
            ... on Qleventoffer {
              id
              eventsOffers {
                endDate
                startDate
                featureImage {
                  node {
                    mediaItemUrl
                  }
                }
              }
              title
              slug
              taxoffers {
                nodes {
                  name
                }
              }
            }
          }
        }
      }
      writeAReviewButton
      locationImage {
        node {
          mediaItemUrl
        }
      }
      locationImageMobile {
        node {
          mediaItemUrl
        }
      }
    }
  }
}
  `;

  const testimonialsQuery = `
    {
      qltestimonials(first: 50, where: {status: PUBLISH, orderby: {field: DATE, order: DESC}}) {
        nodes {
          title
          testimonials {
            name
            testimonial
          }
        }
      }
    }
  `;

  const eventsOffersQuery = `
    {
      latestEventsOffers {
        image
        start_date
        end_date
        title
        offer_types
        slug
      }
      qlentertainments(first: 50, where: {status: PUBLISH, orderby: {field: DATE, order: DESC}}) {
        nodes {
          title
          entertainment {
            featureImage {
              node {
                mediaItemUrl
              }
            }
          }
        }
      }
      qldishes(first: 50, where: {status: PUBLISH, orderby: {field: DATE, order: DESC}}) {
        nodes {
          title
          dishes {
            description
            featureImage {
              node {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  `;

  // Helper function to fetch data
  const fetchData = async (query) => {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT}`,
      {
        httpsAgent: agent,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({query}),
        next: {
          revalidate: 60,
        },
      }
    );
    const { data } = await res.json();
    return data;
  };

  // Fetch queries in parallel
  const [homePageData, testimonialsData, eventsOffersData] = await Promise.all([
    fetchData(homePageQuery),
    fetchData(testimonialsQuery),
    fetchData(eventsOffersQuery),
  ]);

  // Combine results as needed
  return {
    homePage: homePageData.homePage,
    qltestimonials: testimonialsData.qltestimonials,
    latestEventsOffers: eventsOffersData.latestEventsOffers,
    qlentertainments: eventsOffersData.qlentertainments,
    qldishes: eventsOffersData.qldishes,
  };
}

export default function Home() {
  const deviceType = useDeviceType();

  const [activeIndex, setActiveIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const [homePage, setHomePage] = useState(null);

  const [banner_type, setBannerType] = useState("Video");
  const [banner_video, setBannerVideo] = useState("");
  const [banner_video_mobile, setBannerVideoMobile] = useState("");
  const [banner_images, setBannerImage] = useState([]);
  const [welcome_title, setWelcomeTitle] = useState("");
  const [welcome_description, setWelcomeDescription] = useState("");
  const [awards_creative_title, setAwardsCreativeTitle] = useState("");
  const [awards_title, setAwardsTitle] = useState("");
  const [awards_description, setAwardsDescription] = useState("");

  const [city_locations, setCityLocations] = useState([]);
  const [city_locations_bg, setCityLocationsBg] = useState("/cityview.jpg");
  const [city_locations_bg_mob, setCityLocationsBgMob] = useState("/cityview.jpg");

  const [is_food_menu_disabled, setIsFoodMenuDisabled] = useState(false);
  const [food_menu_creative_title, setFoodMenuCreativeTitle] = useState("");
  const [food_menu_title, setFoodMenuTitle] = useState("");
  const [food_menu_description, setFoodMenuDescription] = useState("");
  const [food_menu_image, setFoodMenuImage] = useState("/home/food_menu.png");
  const [food_menu_file, setFoodMenuFile] = useState("");
  const [is_drink_menu_disabled, setIsDrinkMenuDisabled] = useState(false);
  const [drink_menu_creative_title, setDrinkMenuCreativeTitle] = useState("");
  const [drink_menu_title, setDrinkMenuTitle] = useState("");
  const [drink_menu_description, setDrinkMenuDescription] = useState("");
  const [drink_menu_list, setDrinkMenuList] = useState([]);
  const [drink_menu_image, setDrinkMenuImage] = useState(
    "/home/drink_menu.png",
  );
  const [drink_menu_file, setDrinkMenuFile] = useState("");
  const [drink_menu_video, setDrinkMenuVideo] = useState("");
  const [drink_menu_video_mobile, setDrinkMenuVideoMobile] = useState("");
  const [is_entertainment_disabled, setIsEntertainmentDisabled] =
    useState(false);
  const [entertainment_creative_title, setEntertainmentCreativeTitle] =
    useState("");
  const [entertainment_title, setEntertainmentMenuTitle] = useState("");
  const [entertainment_description, setEntertainmentMenuDescription] =
    useState("");

  const [is_events_and_offers_disabled, setIsEventsAndOffersDisabled] =
    useState(false);
  const [events_and_offers_creative_title, setEventsAndOffersCreativeTitle] =
    useState("");
  const [events_and_offers_title, setEventsAndOffersTitle] = useState("");

  const [is_testimonials_disabled, setIsTestimonialsDisabled] = useState(false);
  const [testimonials_creative_title, setTestimonialsCreativeTitle] =
    useState("");
  const [testimonials_title, setTestimonialsTitle] = useState("");
  const [testimonials_list, setTestimonialList] = useState([]);
  const [events_list, setEventsList] = useState([]);
  const [events_list_selected, setEventsListSelected] = useState([]);
  const [entertainment_list, setEntertainmentList] = useState([]);
  const [dishes_list, setDishestList] = useState([]);

  const [is_sin_disabled, setIsSinDisabled] = useState(false);
  const [sin_logo, setSinLogo] = useState("");
  const [sin_description, setSinDescription] = useState("");
  const [sin_url, setSinUrl] = useState("");
  const [sin_feature_list, setSinFeatureList] = useState([]);

  const [is_insta_disabled, setIsInsatDisabled] = useState(false);
  const [insta_title, setInsataTitle] = useState("");
  const [insta_creative_title, setInsataCreativeTitle] = useState("");
  const [insta_profile, setInsataProfile] = useState("");
  const [insta_profile_url, setInsataProfileUrl] = useState("");

  const [review_url, setReviewUrl] = useState("");

  const [awards_list, setAwardsList] = useState([]);

  const [accessToken, setAccessToken] = useState('');
  const [userId, setUserId] = useState('');

  const [pre_loader_enabled, setPreLoaderEnable] = useState(true);

  useEffect(() => {
    const getTokenAndUserId = async () => {
      // Replace this with your actual long-lived access token
      const longLivedToken = process.env.INSTAGRAM_ACCESS_KEY;
      setAccessToken(longLivedToken);

      const id = await fetchInstagramUserId(longLivedToken);
      setUserId(id);
    };

    getTokenAndUserId();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const page = await getHomePage();

      if (page) {
     //  console.log(page);
        setHomePage(page);
        if (page.qltestimonials) {
          setTestimonialList(page.qltestimonials);
        }

        if(page.homePage && page.homePage.homePage){
          if(page.homePage.homePage.upcomingOffers){
            setEventsListSelected(page.homePage.homePage.upcomingOffers);
          }else{
            if (page.latestEventsOffers) {
              setEventsList(page.latestEventsOffers);
            }
          }
        }

        if (page.qlentertainments) {
          setEntertainmentList(page.qlentertainments);
        }
        if (page.qldishes) {
          setDishestList(page.qldishes);
        }
        if (page.homePage && page.homePage.homePage) {
          if (page.homePage.homePage.writeAReviewButton) {
            setReviewUrl(
              page.homePage.homePage.writeAReviewButton
            );
          }
          if (page.homePage.homePage.bannerType) {
            if (page.homePage.homePage.bannerType[0]) {
              setBannerType(
                page.homePage.homePage.bannerType[0]
              );
            }
          }
          if (page.homePage.homePage.bannerSliders) {
            setBannerImage(
              page.homePage.homePage.bannerSliders
            );
          }
          if (page.homePage.homePage.locationImage) {
            if (page.homePage.homePage.locationImage.node) {
              if (page.homePage.homePage.locationImage.node.mediaItemUrl) {
                setCityLocationsBg(
                  page.homePage.homePage.locationImage.node.mediaItemUrl
                );
              }
            }
          }
          if (page.homePage.homePage.locationImageMobile) {
            if (page.homePage.homePage.locationImageMobile.node) {
              if (page.homePage.homePage.locationImageMobile.node.mediaItemUrl) {
                setCityLocationsBgMob(
                  page.homePage.homePage.locationImageMobile.node.mediaItemUrl
                );
              }
            }
          }
          if (page.homePage.homePage.awardsAndQuotesList) {
            if (page.homePage.homePage.awardsAndQuotesList) {
              setAwardsList(page.homePage.homePage.awardsAndQuotesList);
            }
          }
          if (page.homePage.homePage.bannerSliders) {
            setBannerImage(
              page.homePage.homePage.bannerSliders
            );
          }
          if (page.homePage.homePage.bannerMobileVideo) {
            if (
              page.homePage.homePage.bannerMobileVideo.node &&
              page.homePage.homePage.bannerMobileVideo.node.mediaItemUrl
            ) {
              setBannerVideoMobile(
                page.homePage.homePage.bannerMobileVideo.node.mediaItemUrl,
              );
            }
          }
          if (page.homePage.homePage.bannerVideo) {
            if (
              page.homePage.homePage.bannerVideo.node &&
              page.homePage.homePage.bannerVideo.node.mediaItemUrl
            ) {
              setBannerVideo(
                page.homePage.homePage.bannerVideo.node.mediaItemUrl,
              );
            }
          }
          if (page.homePage.homePage.welcomeSectionTitle) {
            setWelcomeTitle(page.homePage.homePage.welcomeSectionTitle);
          }
          if (page.homePage.homePage.welcomeSectionDescription) {
            setWelcomeDescription(
              page.homePage.homePage.welcomeSectionDescription,
            );
          }
          if (page.homePage.homePage.awardsSectionCreativeHeading) {
            setAwardsCreativeTitle(
              page.homePage.homePage.awardsSectionCreativeHeading,
            );
          }
          if (page.homePage.homePage.awardsSectionTitle) {
            setAwardsTitle(page.homePage.homePage.awardsSectionTitle);
          }
          if (page.homePage.homePage.awardsSectionDescription) {
            setAwardsDescription(
              page.homePage.homePage.awardsSectionDescription,
            );
          }
          if (page.homePage.homePage.locationList) {
            setCityLocations(page.homePage.homePage.locationList);
          }
          if (page.homePage.homePage.foodMenuCreativeHeading) {
            setFoodMenuCreativeTitle(
              page.homePage.homePage.foodMenuCreativeHeading,
            );
          }
          if (page.homePage.homePage.foodMenuTitle) {
            setFoodMenuTitle(page.homePage.homePage.foodMenuTitle);
          }
          if (page.homePage.homePage.disableFoodMenu) {
            setIsFoodMenuDisabled(page.homePage.homePage.disableFoodMenu);
          }
          if (page.homePage.homePage.foodMenuDescription) {
            setFoodMenuDescription(page.homePage.homePage.foodMenuDescription);
          }
          if (page.homePage.homePage.foodMenuFeatureImage) {
            if (
              page.homePage.homePage.foodMenuFeatureImage.node &&
              page.homePage.homePage.foodMenuFeatureImage.node.mediaItemUrl
            ) {
              setFoodMenuImage(
                page.homePage.homePage.foodMenuFeatureImage.node.mediaItemUrl,
              );
            }
          }
          if (page.homePage.homePage.disableDrinkMenu) {
            setIsDrinkMenuDisabled(page.homePage.homePage.disableDrinkMenu);
          }
          if (page.homePage.homePage.drinkMenuCreativeHeading) {
            setDrinkMenuCreativeTitle(
              page.homePage.homePage.drinkMenuCreativeHeading,
            );
          }
          if (page.homePage.homePage.drinkMenuTitle) {
            setDrinkMenuTitle(page.homePage.homePage.drinkMenuTitle);
          }
          if (page.homePage.homePage.drinkMenuDescription) {
            setDrinkMenuDescription(
              page.homePage.homePage.drinkMenuDescription,
            );
          }
          if (page.homePage.homePage.drinkMenuSampleList) {
            setDrinkMenuList(page.homePage.homePage.drinkMenuSampleList);
          }
          if (page.homePage.homePage.drinkMenuFeatureImage) {
            if (
              page.homePage.homePage.drinkMenuFeatureImage.node &&
              page.homePage.homePage.drinkMenuFeatureImage.node.mediaItemUrl
            ) {
              setDrinkMenuImage(
                page.homePage.homePage.drinkMenuFeatureImage.node.mediaItemUrl,
              );
            }
          }
          if (page.homePage.homePage.drinkMenuFeatureVideo) {
            if (
              page.homePage.homePage.drinkMenuFeatureVideo.node &&
              page.homePage.homePage.drinkMenuFeatureVideo.node.mediaItemUrl
            ) {
              setDrinkMenuVideo(
                page.homePage.homePage.drinkMenuFeatureVideo.node.mediaItemUrl,
              );
            }
          }
          if (page.homePage.homePage.featureMobileVideo) {
            if (
              page.homePage.homePage.featureMobileVideo.node &&
              page.homePage.homePage.featureMobileVideo.node.mediaItemUrl
            ) {
              setDrinkMenuVideoMobile(
                page.homePage.homePage.featureMobileVideo.node.mediaItemUrl,
              );
            }
          }
          if (page.homePage.homePage.disableEntertainment) {
            setIsEntertainmentDisabled(
              page.homePage.homePage.disableEntertainment,
            );
          }
          if (page.homePage.homePage.entertainmentCreativeHeading) {
            setEntertainmentCreativeTitle(
              page.homePage.homePage.entertainmentCreativeHeading,
            );
          }
          if (page.homePage.homePage.entertainmentTitle) {
            setEntertainmentMenuTitle(
              page.homePage.homePage.entertainmentTitle,
            );
          }
          if (page.homePage.homePage.entertainmentDescription) {
            setEntertainmentMenuDescription(
              page.homePage.homePage.entertainmentDescription,
            );
          }

          if (page.homePage.homePage.disableEventsAndOffers) {
            setIsEventsAndOffersDisabled(
              page.homePage.homePage.disableEventsAndOffers,
            );
          }
          if (page.homePage.homePage.eventsAndOffersCreativeHeading) {
            setEventsAndOffersCreativeTitle(
              page.homePage.homePage.eventsAndOffersCreativeHeading,
            );
          }
          if (page.homePage.homePage.eventsAndOffersTitle) {
            setEventsAndOffersTitle(
              page.homePage.homePage.eventsAndOffersTitle,
            );
          }
          if (page.homePage.homePage.disableTestimonials) {
            setIsTestimonialsDisabled(
              page.homePage.homePage.disableTestimonials,
            );
          }
          if (page.homePage.homePage.testimonialsCreativeHeading) {
            setTestimonialsCreativeTitle(
              page.homePage.homePage.testimonialsCreativeHeading,
            );
          }
          if (page.homePage.homePage.testimonialsTitle) {
            setTestimonialsTitle(page.homePage.homePage.testimonialsTitle);
          }

          if (page.homePage.homePage.disableSin) {
            setIsSinDisabled(page.homePage.homePage.disableSin);
          }
          if (page.homePage.homePage.sinDescription) {
            setSinDescription(page.homePage.homePage.sinDescription);
          }
          if (page.homePage.homePage.sinWebsiteUrl) {
            setSinUrl(page.homePage.homePage.sinWebsiteUrl);
          }
          if (page.homePage.homePage.sinFeaturesd) {
            setSinFeatureList(page.homePage.homePage.sinFeaturesd);
          }
          if (page.homePage.homePage.sinLogo) {
            if (page.homePage.homePage.sinLogo.node) {
              if (page.homePage.homePage.sinLogo.node.mediaItemUrl) {
                setSinLogo(page.homePage.homePage.sinLogo.node.mediaItemUrl);
              }
            }
          }

          if (page.homePage.homePage.disableInstagramFeed) {
            setIsInsatDisabled(page.homePage.homePage.disableInstagramFeed);
          }
          if (page.homePage.homePage.instagramCreativeHeading) {
            setInsataCreativeTitle(page.homePage.homePage.instagramCreativeHeading);
          }
          if (page.homePage.homePage.instagramTitle) {
            setInsataTitle(page.homePage.homePage.instagramTitle);
          }
          if (page.homePage.homePage.instagramUrl) {
            setInsataProfileUrl(page.homePage.homePage.instagramUrl);
          }
          if (page.homePage.homePage.instagramProfileName) {
            setInsataProfile(page.homePage.homePage.instagramProfileName);
          }

          if (page.homePage.homePage.drinkMenu) {
            if (page.homePage.homePage.drinkMenu.node) {
              if (page.homePage.homePage.drinkMenu.node.mediaItemUrl) {
                setDrinkMenuFile(page.homePage.homePage.drinkMenu.node.mediaItemUrl);
              }
            }
          }
          if (page.homePage.homePage.foodMenu) {
            if (page.homePage.homePage.foodMenu.node) {
              if (page.homePage.homePage.foodMenu.node.mediaItemUrl) {
                setFoodMenuFile(page.homePage.homePage.foodMenu.node.mediaItemUrl);
              }
            }
          }
 
        }
        setPreLoaderEnable(false);
      }
    };

    fetchData();
    return () => {};
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    infinite: awards_list.length > 5,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    focusOnSelect: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 5,
          speed: 8000,
          cssEase: "linear",
          autoplaySpeed: 0,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          autoplaySpeed: 5000,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          autoplaySpeed: 5000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          autoplaySpeed: 5000,
        },
      },
    ],
  };

  const settingsdine = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    focusOnSelect: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          fade: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          fade: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          fade: true,
        },
      },
    ],
  };

  const settingsent = {
    dots: false,
    arrows: true,
    infinite: entertainment_list.length > 2,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    focusOnSelect: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const settingoffer = {
    dots: false,
    arrows: false,
    infinite: events_list.length > 3,
    slidesToShow: 2,
    slidesToScroll: 1,
    focusOnSelect: true,
    pauseOnHover: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 5000,
        },
      },
    ],
  };

  const settingtestimonial = {
    dots: true,
    arrows: false,
    infinite: testimonials_list.length > 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 8000,
    focusOnSelect: true,
    pauseOnHover: true,
  };

  const settingssin = {
    dots: false,
    arrows: false,
    infinite: sin_feature_list.length > 3,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    focusOnSelect: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 5000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 5000,
        },
      },
    ],
  };
  

  useEffect(() => {
    if (isHovered) return;

    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % city_locations.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [isHovered, city_locations.length]);

  const handleMouseEnter = (index) => {
    setIsHovered(true);
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (!homePage) return;

    const initializeScroll = async () => {
      const LocomotiveScroll = (await import("locomotive-scroll")).default;
      const locomotiveScroll = new LocomotiveScroll();

    };

    initializeScroll();

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, [homePage]);

  if (pre_loader_enabled) {
    return <Loading />;
  }

  return (
    <>
      <section className={styles.banner_section}>
        {banner_type == "Video" && (
          <video autoPlay muted loop>
            <source
              src={deviceType !== "mobile" ? banner_video : banner_video_mobile}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}
        {banner_type == "Image" && (
          <div className="inner-banner position-relative">
            <Carousel fade className='page-inner-carousel'>
              {banner_images.map((banner_img, index) => {

              let imgurl = '';

              if(banner_img.bannerImage1920X1080){
                if(banner_img.bannerImage1920X1080.node){
                  if(banner_img.bannerImage1920X1080.node.mediaItemUrl){
                    imgurl = banner_img.bannerImage1920X1080.node.mediaItemUrl;
                  }
                }
              }

              return(
              <Carousel.Item key={index}>
                <Image  
                  className={styles.banner_section_main_img}
                  src={imgurl}
                  width={deviceType !== "mobile" ? 1920 : 400}
                  height={deviceType !== "mobile" ? 1080 : 800}
                  alt="Banner Image"
                  priority
                  quality={deviceType !== "mobile" ? 50 : 80}
                />
              </Carousel.Item>
              );}
              )}
            </Carousel>
        </div>
        )}
        <div className={`${styles.banner_logo} desktop_el z-top`}>
          <Image  
            data-scroll
            data-scroll-speed="0.3"
            src="/logo-gif.svg"
            width={380}
            height={151}
            alt="Seen logo"
            priority
          />
        </div>
        <div className={styles.scrolldown}>
          <Image  
            src="/home/line-scroll.svg"
            width={380}
            height={151}
            alt="Scroll Down"
            className="scroll-down-line"
          />
        </div>
      </section>
      <section className="section-padding-top overflow-hidden position-relative section-padding-bottom dark-green-bg">
        <Image  
          className={styles.white_mesh}
          src="/white-mesh.png"
          width={1920}
          height={500}
          alt="white mesh"
        />
        <Image  
          className={styles.city_bg}
          src={
            deviceType !== "mobile"
              ? city_locations_bg
              : city_locations_bg_mob
          }
          width={deviceType !== "mobile" ? 1920 : 400}
          height={deviceType !== "mobile" ? 2300 : 1500}
          alt="City view"
          quality={deviceType !== "mobile" ? 100 : 100}
        />
        <div className="cloud-wrapper">
          <div id="background-wrap">
            <div className="x1">
              <div className="cloud">
                <Image  
                  src="/cloud-2.png"
                  width={851}
                  height={503}
                  alt="Cloud"
                />
              </div>
            </div>
            <div className="x2">
              <div className="cloud">
                <Image  
                  src="/cloud-2.png"
                  width={851}
                  height={503}
                  alt="Cloud"
                />
              </div>
            </div>
            <div className="x3">
              <div className="cloud">
                <Image  
                  alt="Cloud"
                  src="/cloud-2.png"
                  width={851}
                  height={503}
                />
              </div>
            </div>
            <div className="x4">
              <div className="cloud">
                <Image  
                  src="/cloud-2.png"
                  width={851}
                  height={503}
                  alt="Cloud"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="middle-wrap flex-wrap index-top section-padding-top  section-padding-bottom d-flex justify-content-between align-items-center">
          <div className="half-div tab-full-div">
            <h1
              data-scroll
              data-scroll-class="animated"
              data-scroll-offset="25%,50%"
              className="section-heading section-heading--yellow text-right heading-padding animated-section-heading"
            >
              {welcome_title}
            </h1>
          </div>
          <div className="half-div tab-full-div">
            <p className="paragraph paragraph--white m-0">
              {welcome_description}
            </p>
          </div>
        </div>
        <div className="text-center index-top small-wrap">
          <Image  
            src="/btn-line-yellow.png"
            width={70}
            height={10}
            alt="Corner Border"
          />
          <h6 className="creative-heading creative-heading--white">
            {awards_creative_title}
          </h6>
          <h2 className="section-heading section-heading--cursive section-heading--yellow heading-padding">
            {awards_title}
          </h2>
          <p className="paragraph paragraph--white heading-padding">
            {awards_description}
          </p>
        </div>
        {awards_list && awards_list.length !== 0 &&(
        <div className="index-top">
          <Slider className="awards-slider" {...settings}>
          {awards_list.map((awd_item, index) => {

            let type = "";
            let quotesub = "";
            let quote = "";
            let imagurl = "";

            if(awd_item.type){
              if(awd_item.type[0]){
                type = awd_item.type[0];

                if(type === "Award"){
                  if(awd_item.award){
                    if(awd_item.award.nodes){
                      if(awd_item.award.nodes[0]){
                        if(awd_item.award.nodes[0].awardsPost){
                          if(awd_item.award.nodes[0].awardsPost.awardsImage){
                            if(awd_item.award.nodes[0].awardsPost.awardsImage.node){
                              if(awd_item.award.nodes[0].awardsPost.awardsImage.node.mediaItemUrl){
                                imagurl = awd_item.award.nodes[0].awardsPost.awardsImage.node.mediaItemUrl;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }else{
                  if(awd_item.quoteSubtitle){
                    quotesub = awd_item.quoteSubtitle;
                  }
                  if(awd_item.quote){
                    quote = awd_item.quote;
                  }
                }

              }
            }

            return (
              <div key={index}>
              {type === "Award" &&(
                <div>
                  <div className={styles.awrads_box}>
                      <Image  
                        src={imagurl}
                        width={deviceType !== "mobile" ? 500 : 360}
                        height={deviceType !== "mobile" ? 400 : 400}
                        alt="Banner Image"
                      />
                  </div>
                </div>
              )}
              {type !== "Award" &&(
                <div>
                  <div className={styles.awrads_box_text}>
                    <p className="creative-heading creative-heading--yellow m-0">
                      {quote}
                    </p>
                    <Image  
                      src="/btn-line-yellow.png"
                      width={70}
                      height={10}
                      alt="yellow underline"
                    />
                    <p className="paragraph-small paragraph--white m-0">{quotesub}</p>
                  </div>
                </div>
              )}
              </div>
            );}
            )}
          </Slider>
        </div>
        )}
        <div className={styles.city_view}>
          {city_locations.map((location, index) => {

            let icon = "/map-pin.png";

            if(location.icon){
              if(location.icon.node){
                if(location.icon.node.mediaItemUrl){
                  icon = location.icon.node.mediaItemUrl;
                }
              }
            }

            return(
            <div
              key={index}
              className={`${styles.city_view_box} ${styles[`city_view_box_${index}`]} city_view_box ${index === activeIndex ? "activediv" : ""}`}
            >
              <div
                className={`${styles.city_view_box_details} city_view_box_details`}
              >
                <p className="paragraph-small paragraph--white m-0">
                  {location.description}
                </p>
                <div
                  className={`next-btn-bottom-line next-btn next-btn--yellow`}
                >
                  <div className="next-btn--bottom city-btn d-flex w-100">
                    <span className="next-btn--bottom-line"></span>
                    <span className="next-btn--bottom-square"></span>
                    <span className="next-btn--bottom-line"></span>
                  </div>
                </div>
              </div>

              <div
                className="d-flex flex-column justify-content-center align-items-center cursor-add"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <h6 className="paragraph-xsmall paragraph--yellow all-caps para-bold m-0">
                  {location.location}
                </h6>
                <Image  
                  className={styles.city_view_box_pin}
                  src={icon}
                  width={50}
                  height={50}
                  alt="Pin icon"
                />
              </div>
            </div>
            );}
          )}
        </div>
      </section>
      {!is_sin_disabled &&(
      <section className={`${styles.sin_section} section-padding-bottom section-high-padding-top`}>
          <div className="small-wrap">
            <Image  
            className={styles.sin_section__logo}
            src={sin_logo}
            width={600}
            height={300}
            alt="corenr border"
            data-scroll
            data-scroll-speed={
              deviceType === "mobile" || deviceType === "tablet"
                ? "0.1"
                : "0.1"
            }
            />
            <p className="text-center paragraph-large paragraph-large-garamond paragraph--white heading-padding">{sin_description}</p>
          </div>
          <div className="main-wrapper">
              <Slider className={`ent-slider sin-slider ${styles.sin_slider}`} {...settingssin}>
              {sin_feature_list &&
                sin_feature_list.map((sinitem, index) => {

                  let image_url = "";
                  let sin_link = "";

                  if(sinitem.link){
                    sin_link = sinitem.link;
                  }

                  if (sinitem.image) {
                    if (sinitem.image.node) {
                      if (sinitem.image.node.mediaItemUrl) {
                        image_url =
                        sinitem.image.node.mediaItemUrl;
                      }
                    }
                  }

                  return (
                <div className={styles.sin_slider_div} key={index}>
                    <div
                    className={styles.sin_div}>
                    <div className={styles.entertain_slider_div}>
                        <Image  
                          src={image_url}
                          width={deviceType !== "mobile" ? 680 : 350}
                          height={deviceType !== "mobile" ? 750 : 350}
                          alt="entertainment image"
                          quality={100}
                        />
                        <div className={styles.entertain_slider_div_text}>
                          <h6 className="paragraph-small paragraph--yellow all-caps para-bold">
                          {sinitem.title}
                          </h6>
                        </div>
                      </div>
                  </div>
                </div>
                );
                })}
              </Slider>
          </div>
          {sin_url &&(
          <div className="d-flex justify-content-center">
              <Link
                className="next-btn next-btn--white"
                href={sin_url}
              >
                <span className="next-btn--text">explore more</span>
                <div className="next-btn--bottom">
                  <span className="next-btn--bottom-line"></span>
                  <span className="next-btn--bottom-square"></span>
                  <span className="next-btn--bottom-line"></span>
                </div>
              </Link>
            </div>
            )}
      </section>
      )}
      {!is_food_menu_disabled && (
        <section className={`d-flex flex-wrap black-bg ${styles.dine_section}`}>
          <div
            className={`${styles.dine_section__text} cream-mesh  section-high-padding-top d-flex align-items-center section-high-padding-bottom`}
          >
            <Image  
              className={`${styles.corner_bottom} corner_bottom`}
              src="/corner-bottom.svg"
              width={150}
              height={150}
              alt="corner border"
              data-scroll
            />
            <div className="index-top">
              <h6 className="creative-heading-small creative-heading--black">
                {food_menu_creative_title}
              </h6>
              <h2
                data-scroll
                data-scroll-class="animated"
                data-scroll-offset="25%,50%"
                className="section-heading section-heading--black heading-padding animated-section-heading"
              >
                {food_menu_title}
              </h2>
              <p className="paragraph-small paragraph--black heading-padding">
                {food_menu_description}
              </p>
              <Link
                className="next-btn next-btn--black"
                href={`${process.env.APP_URL}/dine-wine`}
              >
                <span className="next-btn--text">Savor Our Menu</span>
                <div className="next-btn--bottom">
                  <span className="next-btn--bottom-line"></span>
                  <span className="next-btn--bottom-square"></span>
                  <span className="next-btn--bottom-line"></span>
                </div>
              </Link>
            </div>
          </div>
          <div className={styles.dine_section__image}>
            <div className={styles.dine_section__image_wrap}>
              <Image  
                src={food_menu_image}
                width={deviceType !== "mobile" ? 545 : 250}
                height={deviceType !== "mobile" ? 890 : 450}
                alt="Dine image"
                quality={100}
              />
            </div>
          </div>
          <div
            className={`${styles.dine_section__slider} bg-dark-green green-mesh  section-padding-top section-padding-bottom position-relative`}
          >
            <Image  
              className={`${styles.corner_top} corner_top`}
              src="/corner-top.svg"
              width={150}
              height={150}
              alt="corner border"
              data-scroll
            />
            <Slider className="dish-slider" {...settingsdine}>
              {dishes_list &&
                dishes_list.nodes &&
                dishes_list.nodes.map((dish, index) => {
                  let image_url = "";

                  if (dish.dishes) {
                    if (dish.dishes.featureImage) {
                      if (dish.dishes.featureImage.node) {
                        if (dish.dishes.featureImage.node.mediaItemUrl) {
                          image_url =
                            dish.dishes.featureImage.node.mediaItemUrl;
                        }
                      }
                    }
                  }

                  return (
                    <div key={index}>
                      <div className={`${styles.dine_box} carousel__text`}>
                        <Image  
                          src={image_url}
                          width={800}
                          height={800}
                          alt="Dish Image"
                        />
                        <div className={`${styles.dine_box__title} dine-title`}>
                          <h6 className="creative-heading creative-heading--yellow">
                            {dish.title}
                          </h6>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider>
            {food_menu_file &&(
            <a
              target="_blank"
              className={`${styles.download_menu} next-btn next-btn--yellow`}
              href={food_menu_file}
            >
              <Image  
                src="/down-arrow.png"
                width={50}
                height={50}
                alt="Down arrow"
              />
              <span>Download Food Menu</span>
              <div className="next-btn--bottom">
                <span className="next-btn--bottom-line"></span>
                <span className="next-btn--bottom-square"></span>
                <span className="next-btn--bottom-line"></span>
              </div>
            </a>
            )}
          </div>
        </section>
      )}
      {!is_drink_menu_disabled && (
        <section
          className={`${styles.drinks_div_wrap} d-flex flex-wrap black-bg`}
        >
          <div
            className={`${styles.drinks_div} ${styles.drinks_div__text} cream-mesh in_tab_el display-flex-sec section-high-padding-top align-items-center section-high-padding-bottom`}
          >
            <Image  
              className={`${styles.corner_bottom_right} corner_bottom_right`}
              src="/corner-bottom-right.svg"
              width={150}
              height={150}
              alt="Corner border"
              data-scroll
            />
            <div className="index-top">
              <h6 className="creative-heading-small creative-heading--black">
                {drink_menu_creative_title}
              </h6>
              <h2
                data-scroll
                data-scroll-class="animated"
                data-scroll-offset="25%,50%"
                className="section-heading section-heading--black heading-padding animated-section-heading"
              >
                {drink_menu_title}
              </h2>
              <p className="paragraph-small paragraph--black heading-padding">
                {drink_menu_description}
              </p>
              {drink_menu_list && (
                <ul>
                  {drink_menu_list.map((drink, index) => (
                    <li key={index}>
                      <p className="paragraph paragraph--black m-0 para-bold">
                        {drink.drink}
                      </p>
                      <Image   src="/dot.png" width={10} height={10} alt="dot" />
                    </li>
                  ))}
                </ul>
              )}
              <Link
                className="next-btn next-btn--black"
                href={`${process.env.APP_URL}/dine-wine`}
              >
                <span className="next-btn--text">Savor Our Menu</span>
                <div className="next-btn--bottom">
                  <span className="next-btn--bottom-line"></span>
                  <span className="next-btn--bottom-square"></span>
                  <span className="next-btn--bottom-line"></span>
                </div>
              </Link>
            </div>
          </div>
          <div className={`${styles.drinks_div} ${styles.drinks_div_video}`}>
            <video autoPlay muted loop>
              <source
                src={
                  deviceType !== "mobile"
                    ? drink_menu_video
                    : drink_menu_video_mobile
                }
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className={`${styles.drinks_div} ${styles.drinks_div__img}`}>
            <Image  
              src={drink_menu_image}
              width={deviceType !== "mobile" ? 640 : 320}
              height={deviceType !== "mobile" ? 780 : 350}
              alt="Drink image"
              className={styles.drinks_div__img_main}
              quality={100}
            />
            {drink_menu_file &&(
            <div className={styles.drinks_div__img_btn}>
              <a  target="_blank" className={`next-btn downbtn-bottom next-btn--yellow`} href={drink_menu_file}>
                <Image  
                  src="/down-arrow.png"
                  width={50}
                  height={50}
                  alt="down arrow"
                />
                <span className="next-btn--text">download drink menu</span>
                <div className="next-btn--bottom">
                  <span className="next-btn--bottom-line"></span>
                  <span className="next-btn--bottom-square"></span>
                  <span className="next-btn--bottom-line"></span>
                </div>
              </a>
            </div>
            )}  
          </div>
          <div
            className={`${styles.drinks_div} ${styles.drinks_div__text} display-flex-sec cream-mesh not_in_tab_el section-high-padding-top align-items-center section-high-padding-bottom`}
          >
            <Image  
              className={`${styles.corner_bottom_right} corner_bottom_right`}
              src="/corner-bottom-right.svg"
              width={150}
              height={150}
              alt="Corner Border"
              data-scroll
            />
            <div className="index-top">
              <h6 className="creative-heading-small creative-heading--black">
                {drink_menu_creative_title}
              </h6>
              <h2
                data-scroll
                data-scroll-class="animated"
                data-scroll-offset="25%,50%"
                className="section-heading section-heading--black heading-padding animated-section-heading"
              >
                {drink_menu_title}
              </h2>
              <p className="paragraph-small paragraph--black heading-padding">
                {drink_menu_description}
              </p>
              {drink_menu_list && (
                <ul>
                  {drink_menu_list.map((drink, index) => (
                    <li key={index}>
                      <p className="paragraph paragraph--black m-0 para-bold">
                        {drink.drink}
                      </p>
                      <Image   src="/dot.png" width={10} height={10} alt="dot" />
                    </li>
                  ))}
                </ul>
              )}
              <Link
                className="next-btn next-btn--black"
                href={`${process.env.APP_URL}/dine-wine`}
              >
                <span className="next-btn--text">Savor Our Menu</span>
                <div className="next-btn--bottom">
                  <span className="next-btn--bottom-line"></span>
                  <span className="next-btn--bottom-square"></span>
                  <span className="next-btn--bottom-line"></span>
                </div>
              </Link>
            </div>
          </div>
        </section>
      )}
      {!is_entertainment_disabled &&
        entertainment_list &&
        entertainment_list.nodes &&
        entertainment_list.nodes.length !== 0 && (
          <section className="d-flex flex-wrap black-bg">
            <div
              className={`${styles.entertain_text} cream-mesh section-padding-top section-padding-bottom d-flex align-items-center `}
            >
              <Image  
                className={`${styles.corner_bottom} corner_bottom`}
                src="/corner-bottom.svg"
                width={150}
                height={150}
                alt="corner border"
                data-scroll
              />
              <div className="index-top">
                <h6 className="creative-heading-small creative-heading--black">
                  {entertainment_creative_title}
                </h6>
                <h2
                  data-scroll
                  data-scroll-class="animated"
                  data-scroll-offset="25%,50%"
                  className="section-heading section-heading--black heading-padding animated-section-heading"
                >
                  {entertainment_title}
                </h2>
                <p className="paragraph-small paragraph--black heading-padding">
                  {entertainment_description}
                </p>
                <Link
                  className="next-btn next-btn--black"
                  href={`${process.env.APP_URL}/private-party`}
                >
                  <span className="next-btn--text">explore more</span>
                  <div className="next-btn--bottom">
                    <span className="next-btn--bottom-line"></span>
                    <span className="next-btn--bottom-square"></span>
                    <span className="next-btn--bottom-line"></span>
                  </div>
                </Link>
              </div>
            </div>
            <div className={styles.entertain_slider}>
              <Slider className="ent-slider" {...settingsent}>
                {entertainment_list &&
                  entertainment_list.nodes &&
                  entertainment_list.nodes.map((ent, index) => {
                    let image_url = "";

                    if (ent.entertainment) {
                      if (ent.entertainment.featureImage) {
                        if (ent.entertainment.featureImage.node) {
                          if (
                            ent.entertainment.featureImage.node.mediaItemUrl
                          ) {
                            image_url =
                              ent.entertainment.featureImage.node.mediaItemUrl;
                          }
                        }
                      }
                    }

                    return (
                      <div key={index}>
                        <div className={styles.entertain_slider_div}>
                          <Image  
                            src={image_url}
                            width={deviceType !== "mobile" ? 680 : 350}
                            height={deviceType !== "mobile" ? 750 : 350}
                            alt="entertainment image"
                            quality={100}
                          />
                          <div className={styles.entertain_slider_div_text}>
                            <h6 className="paragraph-small paragraph--yellow all-caps para-bold">
                              {ent.title}
                            </h6>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </section>
        )}
      <section className="section-padding-bottom dark-green-bg-trans position-relative">
        <Image  
          className={`${styles.corner_bottom} corner_bottom index-top`}
          src="/corner-bottom.svg"
          width={150}
          height={150}
          alt="corenr border"
          data-scroll
        />
        {!is_events_and_offers_disabled && (
            <>
              <div className="position-relative section-padding-top">
                <Image  
                  className={`${styles.corner_top} corner_top index-top`}
                  src="/corner-top.svg"
                  width={150}
                  height={150}
                  alt="corner Border"
                  data-scroll
                />
                <div className="xsmall-wrap text-center index-top position-relative">
                  <h6 className="creative-heading-small creative-heading--white">
                    {events_and_offers_creative_title}
                  </h6>
                  <h2
                    data-scroll
                    data-scroll-class="animated"
                    data-scroll-offset="25%,50%"
                    className="section-heading section-heading--yellow heading-padding animated-section-heading"
                  >
                    {events_and_offers_title}
                  </h2>
                </div>
              </div>
              <div className="index-top main-wrapper offer-slider-sec">
                <div className={`${styles.offer_sldier}`}>
                  {events_list && events_list.length !== 0 && (
                    <Slider className="offer-slider" {...settingoffer}>
                      {events_list.map((event, index) => {
                        // Limit the title to 150 characters
                        const truncatedTitle =
                          event.title.length > 150
                            ? `${event.title.substring(0, 150)}[...]`
                            : event.title;

                        const eventDateText = formatEventDate(event.start_date, event.end_date);

                        return (
                          <div key={index}>
                            <div className={`${styles.offer_box} d-flex`}>
                              <div className={styles.offer_box_left}>
                                <div>
                                  {/* {event.offer_types && (
                                    <div className={styles.offer_box_right_tag}>
                                      {event.offer_types}
                                    </div>
                                  )} */}
                                  {event.offer_types &&(
                                 <div className="d-flex flex-wrap tag-wrap-div justify-content-center">
                                  {event.offer_types.map((item, index) => (
                                    <div className={styles.offer_box_right_tag} key={index}>
                                      {item}
                                    </div>
                                  ))}
                                 </div>
                                 )}
                                  <p className="paragraph-xsmall paragraph--black para-bold">
                                    {eventDateText}
                                  </p>
                                  <h5 className="creative-heading creative-heading--black heading-padding-large">
                                    {truncatedTitle}
                                  </h5>
                                  <Link
                                    className="next-btn next-btn--black"
                                    href={`/events-and-offers/${event.slug}`}
                                  >
                                    <span className="next-btn--text">
                                      more details
                                    </span>
                                    <div className="next-btn--bottom">
                                      <span className="next-btn--bottom-line"></span>
                                      <span className="next-btn--bottom-square"></span>
                                      <span className="next-btn--bottom-line"></span>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                              <div className={styles.offer_box_right}>
                                
                                <Image  
                                  src={event.image}
                                  width={450}
                                  height={450}
                                  alt="Offer image"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  )}

                  {events_list_selected && events_list_selected.length !== 0 && (
                    <Slider className="offer-slider" {...settingoffer}>
                      {events_list_selected.map((event, index) => {
                        
                        let title = "";
                        let slug = "";
                        let imgurl = "";
                        let start_date = "";
                        let end_date = "";
                        let off_type = [];

                        if(event.eventAndOffer){
                          if(event.eventAndOffer.nodes){
                            if(event.eventAndOffer.nodes[0]){
                              if(event.eventAndOffer.nodes[0].title){
                                title = event.eventAndOffer.nodes[0].title;
                              }
                              if(event.eventAndOffer.nodes[0].slug){
                                slug = event.eventAndOffer.nodes[0].slug;
                              }
                              if(event.eventAndOffer.nodes[0].taxoffers){
                                if(event.eventAndOffer.nodes[0].taxoffers.nodes){
                                  // if(event.eventAndOffer.nodes[0].taxoffers.nodes[0]){
                                  //   if(event.eventAndOffer.nodes[0].taxoffers.nodes[0].name){
                                  //     off_type = event.eventAndOffer.nodes[0].taxoffers.nodes[0].name;
                                  //   }
                                  // }
                                  off_type= event.eventAndOffer.nodes[0].taxoffers.nodes;
                                }
                                
                              }
                              if(event.eventAndOffer.nodes[0].eventsOffers){
                                if(event.eventAndOffer.nodes[0].eventsOffers.startDate){
                                  start_date = event.eventAndOffer.nodes[0].eventsOffers.startDate;
                                }
                                if(event.eventAndOffer.nodes[0].eventsOffers.endDate){
                                  end_date = event.eventAndOffer.nodes[0].eventsOffers.endDate;
                                }
                                if(event.eventAndOffer.nodes[0].eventsOffers.featureImage){
                                  if(event.eventAndOffer.nodes[0].eventsOffers.featureImage.node){
                                    if(event.eventAndOffer.nodes[0].eventsOffers.featureImage.node.mediaItemUrl){
                                      imgurl = event.eventAndOffer.nodes[0].eventsOffers.featureImage.node.mediaItemUrl;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }


                        const truncatedTitle =
                        title.length > 150
                            ? `${title.substring(0, 150)}[...]`
                            : title;

                        const eventDateText = formatEventDate(start_date, end_date);

                        return (
                          <div key={index}>
                            <div className={`${styles.offer_box} d-flex`}>
                              <div className={styles.offer_box_left}>
                                <div>
                                  {off_type &&(
                                 <div className="d-flex flex-wrap tag-wrap-div justify-content-center">
                                  {off_type.map((item, index) => (
                                    <div className={styles.offer_box_right_tag} key={index}>
                                      {item.name}
                                    </div>
                                  ))}
                                 </div>
                                 )}
                                  <p className="paragraph-xsmall paragraph--black para-bold">
                                    {eventDateText}
                                  </p>
                                  <h5 className="creative-heading creative-heading--black heading-padding-large">
                                    {truncatedTitle}
                                  </h5>
                                  <Link
                                    className="next-btn next-btn--black"
                                    href={`/events-and-offers/${slug}`}
                                  >
                                    <span className="next-btn--text">
                                      more details
                                    </span>
                                    <div className="next-btn--bottom">
                                      <span className="next-btn--bottom-line"></span>
                                      <span className="next-btn--bottom-square"></span>
                                      <span className="next-btn--bottom-line"></span>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                              <div className={styles.offer_box_right}>
                                <Image  
                                  src={imgurl}
                                  width={450}
                                  height={450}
                                  alt="Offer image"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  )}
                  <div className="d-flex justify-content-center">
                    <Link
                      className="next-btn next-btn--yellow"
                      href={`${process.env.APP_URL}/events-offers`}
                    >
                      <span className="next-btn--text">explore more</span>
                      <div className="next-btn--bottom">
                        <span className="next-btn--bottom-line"></span>
                        <span className="next-btn--bottom-square"></span>
                        <span className="next-btn--bottom-line"></span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        {!is_testimonials_disabled && (
          <div className="middle-wrap flex-wrap index-top d-flex justify-content-between section-padding-top">
            <div
              className={`${styles.testimonial_left} section-padding-bottom`}
            >
              <h6 className="creative-heading-small creative-heading--white">
                {testimonials_creative_title}
              </h6>
              <h2
                data-scroll
                data-scroll-class="animated"
                data-scroll-offset="25%,50%"
                className="section-heading section-heading--yellow heading-padding-large animated-section-heading"
              >
                {testimonials_title}
              </h2>
              {review_url &&(
              <a className="next-btn next-btn--yellow d-block" target="_blank" href={review_url}>
                <span className="next-btn--text">write a review</span>
                <div className="next-btn--bottom">
                  <span className="next-btn--bottom-line"></span>
                  <span className="next-btn--bottom-square"></span>
                  <span className="next-btn--bottom-line"></span>
                </div>
              </a>
              )}
            </div>
            <div className={styles.testimonial_right}>
              <Slider className="testimonial-slider" {...settingtestimonial}>
                {testimonials_list &&
                  testimonials_list.nodes &&
                  testimonials_list.nodes.map((testimonial, index) => {
                    return (
                      <div key={index}>
                        <div className="carousel__text">
                          <h6 className="creative-heading-large creative-heading--yellow heading-padding">
                            {testimonial.title}
                          </h6>
                          <TruncatedText
                            title={testimonial.title}
                            text={
                              testimonial.testimonials &&
                              testimonial.testimonials.testimonial
                            }
                            name={
                              testimonial.testimonials &&
                              testimonial.testimonials.name
                            }
                          />
                          <div className="next-btn next-btn--yellow">
                            <div className="next-btn--bottom">
                              <span className="next-btn--bottom-line"></span>
                              <span className="next-btn--bottom-square"></span>
                              <span className="next-btn--bottom-line"></span>
                            </div>
                          </div>
                          <p className="paragraph-small paragraph--white">
                            {testimonial.testimonials &&
                              testimonial.testimonials.name}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        )}
      </section>
      {!is_insta_disabled &&(
      <section className="section-padding-bottom section-padding-top cream-mesh bg-cream">
        <div className="main-wrapper position-relative z-top d-flex insta-section flex-wrap justify-content-between align-items-center">
          <div className="insta-left">
                <h6 className="creative-heading-small creative-heading--black">
                  {insta_creative_title}
                </h6>
                <h2
                  className="section-heading section-heading--black heading-padding"
                >
                  {insta_title}
                </h2>
          </div>
          <div className="insta-right">
            <a href={insta_profile_url} target="_blank" className="paragraph-large paragraph--black insta-link">{insta_profile}</a>
          </div>
        </div>
        <div className="main-wrapper z-top position-relative">
          <div className="insta-widget ">
             {accessToken && userId && <InstagramFeed accessToken={accessToken} userId={userId} />}
          </div>
        </div>
      </section>
      )}
    </>
  );
}
